import React from "react";
import { Row, Col } from "antd";
import "./services.scss";
import { FadeInSection } from "../FadeInSection";

const ServiceCard = ({ service }) => {
  return (
    <>
      <Col
        md={{ span: 24 }}
        lg={{ span: 7 }}
        xs={{ span: 18 }}
        sm={{ span: 20 }}
        className="m-3 serviceWrapper  "
      >
        <FadeInSection>
          <Row className="px-3 py-4 align-items-center ">
            <Col md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <div className="my-3 service-icon">{service.icon}</div>
              <div className="font-20 text-color service-icon ">
                {service.title}
              </div>
              <div>
                <p className="font-14 text-color service-icon">
                  {service.description}
                </p>
              </div>
            </Col>
          </Row>
        </FadeInSection>
      </Col>
    </>
  );
};

export default  ServiceCard;