import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './pages.scss';

export const NavBar = () => {
  const [visible, setVisible] = useState(false);

  const menuList = [
    { id: 1, label: 'Home', to: '/' },
    // { id: 2, label: 'Services', to: 'services' },
    { id: 3, label: 'Blogs', to: '/blogs' },
    { id: 4, label: 'Careers', to: '/careers' },
    { id: 5, label: 'Contact Us', to: '/contact' },
    // { id: 6, label: 'Schedule Call', to: '/call' },
  ];

  const toggleDrawer = () => {
    setVisible(!visible);
  };

  return (
    <nav className="navbar">
      <Button
        className="navBtn"
        icon={visible ? <CloseOutlined /> : <MenuOutlined size={'large'} />}
        onClick={toggleDrawer}
      />
      <Drawer
        placement="right"
        onClick={toggleDrawer}
        onClose={toggleDrawer}
        visible={visible}
        width={'250px'}
      >
        <ul className="navbar-nav align-items-center ulDiv">
          {menuList.map((item) => (
            <li className="nav-item" key={item.id}>
              <Link
                className="nav-link font-nav"
                to={item.to}
                smooth={true}
                duration={500}
                activeClass="active"
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </Drawer>
    </nav>
  );
};
