import React, { useState, useEffect } from "react";
import "./Careers.scss";
import { FadeInSection } from "../FadeInSection";
import { Card, Typography, Row, Col, Button } from "antd";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import clientOfficialCareers from "../../clientOfficialCareers";
const { Title, Text } = Typography;

const Careers = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    clientOfficialCareers
      .fetch('*[_type == "job"]')
      .then((data) => {
        console.log(data); // Log the fetched data to check what's being returned
        setJobs(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  console.log(jobs, "Jobs");

  const handleJobClick = (job) => {
    setSelectedJob(job);
  };

  const handlePageClose = () => {
    setSelectedJob(null);
  };

  return (
    <>
      {isLoading ? (
        <h1 className="loaderCss">
          <Box sx={{ display: "flex" }}>
            <CircularProgress style={{ width: "100px", height: "100px" }} />
          </Box>
        </h1>
      ) : (
        <div>
          <div className="hero-section">
            <div className="container">
              <h2> WE ARE HIRING </h2>
              <FadeInSection>
                <h6>Are You Ready For The Challange?</h6>
              </FadeInSection>
            </div>
          </div>
          <FadeInSection>
            <div className="container ">
              <div className="blogWrapper col-12 container" 
              // style={{ padding: "24px" }}
              >
                {!selectedJob && (
                  <Row
                    gutter={[16, 16]}
                    // className="jobstyle"
                    style={{ marginTop: "80px" }}
                  >
                    {jobs.map((data) => {
                      // const imageUrl = `${data.mainImage.asset.url}?w=400&h=80`;
                      return (
                        <Col
                          key={data.slug.current}
                          xs={24}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={8}
                        >
                          <Link to={`/careers/${data.slug.current}`}>
                            <Card
                              onClick={() => handleJobClick(data)}
                              hoverable
                              className="cardCss"
                              style={{ cursor: "pointer" }}
                              // cover={
                              //   <img
                              //     className="imageCss"
                              //     style={{
                              //       objectFit: "cover",
                              //       borderTopLeftRadius: "10px",
                              //       borderTopRightRadius: "10px",
                              //     }}
                              //     alt="Blog Thumbnail"
                              //     src={imageUrl}
                              //   />
                              // }
                            >
                              <div style={{ height: "140px" }}>
                                <Title level={4} ellipsis={{ rows: 2 }}>
                                  {data.title}
                                </Title>
                                <Text ellipsis={{ row: 3 }}>
                                  Full Time/Lahore{" "}
                                </Text>
                                <div
                                  style={{
                                    textAlign: "left",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Button
                                    onClick={() => handleJobClick(data)}
                                    className="third1"
                                  >
                                    Read More
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                )}
                {/* {selectedJob && (
            <JobList 
            data={selectedJob}
            onClose={handlePageClose} />
          )} */}
              </div>
            </div>
          </FadeInSection>
        </div>
      )}
    </>
  );
};

export default Careers;
