import React, { useState, useEffect } from "react";
import { Card, Typography, Row, Col, Button } from "antd";
import { FadeInSection } from "../FadeInSection/index";
import "./blogs.scss";
import sanityClient from "../../client.js";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
const { Title, Text } = Typography;

const BlogList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        "author": author->name,
        slug,
        body,
        mainImage{
          asset->{
          _id,
          url
          },
          alt
        }
    }`
      )
      .then((data) => setBlogs(data))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading ? (
        <h1 className="loaderCss">
          <Box sx={{ display: "flex" }}>
            <CircularProgress style={{ width: "100px", height: "100px" }} />
          </Box>
        </h1>
      ) : (
        <div>
          <div className="hero-blogs">
            <div className="container">
              <h2> DMECHS BLOGS </h2>
              <FadeInSection>
                <h6>
                  Cutting-edge tech content that keeps you ahead of the curve!
                </h6>
              </FadeInSection>
            </div>
          </div>
          <div
            className="blogWrapper col-12 container"
            style={{ padding: "24px" }}
          >
            <Row gutter={[16, 16]} style={{ marginTop: "80px" }}>
              {blogs.map((data) => {
                const imageUrl = `${data.mainImage.asset.url}?w=200&h=160`;
                return (
                  <Col
                    key={data.slug.current}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                  >
                    <Link to={`/blogs/${data.slug.current}`}>
                      <Card
                        hoverable
                        className="cardCss"
                        style={{ cursor: "pointer" }}
                        cover={
                          <img
                            style={{
                              objectFit: "cover",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                            }}
                            alt={data.title}
                            src={imageUrl}
                          />
                        }
                      >
                        <div style={{ height: "130px" }}>
                          <Title level={4} ellipsis={{ rows: 2 }}>
                            {data.title}
                          </Title>
                          <Text ellipsis={{ rows: 3 }}>By: {data.author}</Text>
                          <div style={{ textAlign: "left", marginTop: "20px" }}>
                            <Button className="third1">
                              Read Full Article
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogList;
