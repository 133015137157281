import React from "react";
import { Row, Col } from "antd";
import "./PeopleAndTechnology.scss";
import { PopupButton } from "react-calendly";
import { FadeInSection } from "../FadeInSection";
import { Typography } from "antd";
import MeetingVideo from "../../assets/images/contact/bussiness-grow.webm";

const { Title } = Typography;

const PeopleAndTechnology = (props) => {
  return (
    <div className="people-and-technology-wrapper">
      <div className="left-column">
        <FadeInSection>
          <Col
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            className="col scheduleCall"
          >
            <Row
              id="call"
              className={"align-items-center justify-content-center my-5"}
            >
              <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                <Row>
                  <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Title level={2} className="text-center title">
                      Grow Your Business with DMechs
                    </Title>
                    <p className="pText">
                      We provide innovative solutions to help you grow your
                      business. Our team of experts will help you identify and
                      implement the right strategies to increase your revenue,
                      reduce costs, and improve your bottom line.
                    </p>
                    <PopupButton
                      url="https://calendly.com/dmechs/contactus"
                      rootElement={document.getElementById("root")}
                      text="Schedule a Call"
                      className="btn third"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </FadeInSection>
      </div>
      <div className="right-column">
        <video autoPlay muted loop className="technology-image mt-0">
          <source src={MeetingVideo} type="video/webm" />
        </video>
      </div>
    </div>
  );
};

export default PeopleAndTechnology;

