import React from "react";
import "./assets/scss/App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TopHeader from "./layouts/header";
import PageNotFound from "./pages/pageNotFound";
import Landing from "./components/Home/landing";
import BlogPage from "./components/Blogs/BlogPage"
import Footer from "./layouts/footer";
import Contact from "./pages/contact";
import Careers from "./components/Careers";
import BlogList from "./components/Blogs";
import JobList from "./components/Careers/Modal";

function App() {
  return (
    <div className="basic-utils ">
      <BrowserRouter>
        <TopHeader />
        <div className="bg-body">
          <Routes>
            <Route path="/" element={<Landing />} />
            {/* <Route path="/home" element={<Landing />} /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/blogs" element={<BlogList />} />
            <Route path="/blogs/:slug" element={<BlogPage />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/careers/:slug" element={<JobList />} />
            <Route path="/404" element={<PageNotFound />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
