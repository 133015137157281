import React from "react";
import { RightCircleOutlined } from "@ant-design/icons";
import "./EngagementMdl.scss";
import TeamsIcon from "../../assets/icons/dedicatedteam.webp";
import ProjectIcon from "../../assets/icons/projectbased.webp";
import { FadeInSection } from "../FadeInSection";
import { PopupButton } from "react-calendly";

const EngagementModal = () => {
  return (
    <FadeInSection>
      <section
        className="engagement-models"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="container">
          <div className="heading-section fontCss">
            <h2>Choose the Best Suitable Engagement Model </h2>
            <p>
              Based on your business specifications and project requirements{" "}
            </p>
          </div>
          <div className="eng-models">
            <div className="row eng_modelDir">
              <div className="col-md-12 col-lg-6 cardOne">
                <div className="eng-model">
                  <div className=" model-icon-row">
                    <div className="model-icon">
                      <img src={TeamsIcon} alt="team" width={93} height={95} title="Team" />
                    </div>
                    <div className="headingCss">
                      <h2>Dedicated Hiring </h2>
                    </div>
                  </div>
                  <p>
                    Build your own team by selecting, hiring, and managing
                    highly skilled software developers and IT experts on a
                    long-term basis. This model is intended to help you find and
                    engage dedicated resources with the right expertise and
                    cultural fit to ensure agile software development.
                  </p>
                  <div className="btn-holder">
                    <a href="#" className="button btn-outline ">
                      <PopupButton
                        url="https://calendly.com/dmechs/contactus"
                        rootElement={document.getElementById("root")}
                        text="Contact with Expert"
                        className="btn-text"
                      />
                      <span className="arrow-icon">
                        <RightCircleOutlined className="arrowCss" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 cardTwo">
                <div className="eng-model">
                  <div className="model-icon-row">
                    <div className="model-icon">
                      <img src={ProjectIcon} alt="Projectbased Icon" />
                    </div>
                    <div className="headingCss">
                      <h2>Fixed Cost</h2>
                    </div>
                  </div>
                  <p>
                    Our project-based model, usually at a fixed price, is
                    specifically designed to provide you with the best-in-class
                    software development experts. This model is intended to
                    ensure a swift and reliable collaboration between a client
                    and our IT specialists engaged in a specific project.
                  </p>
                  <div className="btn-holder">
                    <a href="#" className="button btn-outline ">
                      <PopupButton
                        url="https://calendly.com/dmechs/contactus"
                        rootElement={document.getElementById("root")}
                        text="Get Quote"
                        className="btn-text "
                      />
                      <span className="arrow-icon">
                        <RightCircleOutlined className="arrowCss" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </FadeInSection>
  );
};

export default EngagementModal;
