import React from "react";
import { Card, Col, Divider, Input, Row, Typography } from "antd";
import wasif_avatar from "../assets/images/aboutus/wasif_avatar.png";
import salman_avatar from "../assets/images/aboutus/salman_avatar.png";

import { scrollUp } from "../utils/utilSvc";
import ContactImage from "../assets/images/contact/businessman-working-on-laptop.webm";
import { PhoneOutlined, UserOutlined, MailOutlined, FlagOutlined, MessageOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import "./pages.scss";
const { Text } = Typography;
const Contact = () => {
  scrollUp();

  return (
    <>
      <Row className="container col-12 mt-5 mb-5" style={{ paddingBottom: "47px" }}>
        <Col span={24}>
          <Row className="bg-white contact-wrapper">
            <Col xs={24} sm={24} md={15} lg={12} xl={12} className="mx-md-2">
              <div className="px-3 py-3">
                <div className="vertical-line-primary contact-info">
                  <div className="ml-4">
                    <div className="font-weight-bold">Contact Us</div>
                    <form layout="vertical" action="https://api.web3forms.com/submit" method="POST" id="form">
                      <Input
                        type="hidden"
                        name="access_key"
                        value="1e1a71ab-691a-49c0-a3cd-e1e3c9b540ca"
                        // value="26570a79-fbcc-423b-a84e-d49abc9eddce"
                      />
                      <Input type="hidden" name="subject" value="Subject" />
                      <Input type="checkbox" name="botcheck" id="" style={{ display: "none" }} />
                      <Row gutter={16}>
                        <Col span={12}>
                          <label htmlFor="first_name">First Name</label>
                          <Input
                            type="text"
                            name="first_name"
                            id="first_name"
                            placeholder="  John"
                            required
                            className="form-Input"
                            prefix={<UserOutlined />}
                          />
                        </Col>
                        <Col span={12}>
                          <label htmlFor="last_name">Last Name</label>
                          <Input
                            type="text"
                            name="last_name"
                            id="last_name"
                            placeholder="  Doe"
                            required
                            className="form-Input"
                            prefix={<UserOutlined />}
                          />
                        </Col>
                        <Col span={12}>
                          <label htmlFor="email">Email</label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="  abc@gmail.com"
                            required
                            className="form-Input"
                            prefix={<MailOutlined />}
                          />
                        </Col>
                        <Col span={12}>
                          <label htmlFor="phone">Phone</label>
                          <Input
                            type="phone"
                            name="phone"
                            id="phone"
                            placeholder="  +1 (555) 1234-567"
                            required
                            className="form-Input"
                            prefix={<PhoneOutlined />}
                          />
                        </Col>
                        <Col span={12}>
                          <label htmlFor="country">Country</label>
                          <Input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="  Pak/US/Canada"
                            required
                            className="form-Input"
                            prefix={<FlagOutlined />}
                          />
                        </Col>
                        <Col span={12}>
                          <label htmlFor="subject">Subject</label>
                          <Input
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="  Query title"
                            required
                            className="form-Input"
                            prefix={<MessageOutlined />}
                          />
                        </Col>
                        <Col span={24}>
                          <label htmlFor="message" className="label-text">
                            Your Message
                          </label>
                          <TextArea
                            rows="4"
                            autoSize={{ minRows: 4, maxRows: 8 }}
                            name="message"
                            id="message"
                            placeholder="  Your Message"
                            required
                            className="form-textarea"
                          />
                        </Col>
                      </Row>
                      <div className="mb-6 mt-3 text-center">
                        <button type="submit" className="form-button third2">
                          Send Message
                        </button>
                      </div>
                      <p className="form-result" id="result"></p>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={4} lg={6} xl={10} className="mx-md-1 ">
              <video className="contactVid" autoPlay loop muted style={{ width: "900px", height: "500px" }}>
                <source src={ContactImage} type="video/webm" />
              </video>
            </Col>
          </Row>
          {/* <Row className="my-row">
            <Card hoverable className="my-card" style={{ cursor: "pointer" }}>
              <div className="card-content">
                <div className="left-side">
                  <img alt={"none"} src={salman_avatar} className="new-image" />
                  <Text strong className= "role">CEO/Director</Text>
                </div>
                <Divider type="vertical" className="custom-divider" />
                <div className="right-side">
                  <div className="info-item">
                    <Text strong>Full Name:</Text>
                    <Text ellipsis={{ rows: 1 }}>{"M. Salman Nafees"}</Text>
                  </div>

                  <div className="info-item">
                    <Text strong>Email Address:</Text>
                    <Text ellipsis={{ rows: 1 }}>{"salman@dmechs.com"}</Text>
                  </div>

                  <div className="info-item">
                    <Text strong>Phone No:</Text>
                    <Text ellipsis={{ rows: 1 }}>{"+92 300 0332425"}</Text>
                  </div>

                  <div className="info-item">
                    <Text strong>Address:</Text>
                    <Text ellipsis={{ rows: 1 }}>{"450-G Block, Johar Town, Lahore, Pakistan"}</Text>
                  </div>
                </div>
              </div>
            </Card>

            <Card hoverable className="my-card" style={{ cursor: "pointer" }}>
              <div className="card-content">
                <div className="left-side">
                  <img alt={"none"} src={wasif_avatar} className="new-image" />
                  <Text strong className="role" >CTO/Director</Text>
                </div>
                <Divider type="vertical" className="custom-divider" />
                <div className="right-side">
                  <div className="info-item">
                    <Text strong>Full Name:</Text>
                    <Text ellipsis={{ rows: 1 }}>{"Wasif Shehzad"}</Text>
                  </div>

                  <div className="info-item">
                    <Text strong>Email Address:</Text>
                    <Text ellipsis={{ rows: 1 }}>{"wasif@dmechs.com"}</Text>
                  </div>

                  <div className="info-item">
                    <Text strong>Phone No:</Text>
                    <Text ellipsis={{ rows: 1 }}>{"+92 300 7169102"}</Text>
                  </div>

                  <div className="info-item">
                    <Text strong>Address:</Text>
                    <Text ellipsis={{ rows: 1 }}>{"450-G Block, Johar Town, Lahore, Pakistan"}</Text>
                  </div>
                </div>
              </div>
            </Card>
          </Row> */}
        </Col>
      </Row>
    </>
  );
};

export default Contact;

const form = document.getElementById("form");
const result = document.getElementById("result");

form &&
  form.addEventListener("submit", function (e) {
    const formData = new FormData(form);
    e.preventDefault();
    var object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    var json = JSON.stringify(object);
    result.innerHTML = "Please wait...";

    fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    })
      .then(async (response) => {
        let json = await response.json();
        if (response.status === 200) {
          result.innerHTML = json.message;
          form.reset();
          result.classList.remove("text-gray-500");
          result.classList.add("text-green-500");
        } else {
          console.log(response);
          result.innerHTML = json.message;
          result.classList.remove("text-gray-500");
          result.classList.add("text-red-500");
        }
      })
      .catch((error) => {
        console.log(error);
        result.innerHTML = "Something went wrong!";
      })
      .then(function () {
        form.reset();
        setTimeout(() => {
          result.style.display = "none";
        }, 5000);
      });
  });
