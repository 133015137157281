import React from "react";
import { Row, Col, Menu } from "antd";
import {
  HomeOutlined,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import "./footer.scss";
import LogoImg from "../../assets/images/Logo/Logo with Text.webp";
import FacebookIcon from "@mui/icons-material/Facebook";

const Footer = () => {
  return (
    // <footer className="footer">
    <div className="footerMainWrapper">
      {/* <div className="waves">
        <div className="wave" id="wave1"></div>
        <div className="wave" id="wave2"></div>
        <div className="wave" id="wave3"></div>
        <div className="wave" id="wave4"></div>
      </div> */}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={4} md={4} lg={4} xl={4} className="logoDiv">
          <img src={LogoImg} className="logoCss" alt="Logo" />
        </Col>
        <Col xs={24} sm={12} md={13} lg={14} xl={15} className="company">
          {/* <Menu mode="vertical" className="menu">
            <Menu.Item key="1">COMPANY</Menu.Item>
            <Menu.Item key="2">What we do</Menu.Item>
            <Menu.Item key="3">How it works</Menu.Item>
            <Menu.Item key="4">Portfolio</Menu.Item>
            <Menu.Item key="5">Events</Menu.Item>
          </Menu> */}
          <div className="socialIcons ">
            <a href="https://www.facebook.com/dmechs">
              <FacebookIcon style={{ fontSize: '300%'}} className="fbiconCss" />
            </a>
            <a href="https://www.linkedin.com/company/d-mechs-pvt-ltd/">
              <LinkedinFilled style={{ fontSize: '250%', verticalAlign:'middle'}} className="iconCss" />
            </a>
          </div>
          <p className="copyright">&copy; {new Date().getFullYear()} DMechs</p>
        </Col>
        <Col xs={24} sm={8} md={7} lg={6} xl={5} className="thirdDiv">
          <div className="thirdDivWrapper">
            <div className="contactInnerDiv">
              <HomeOutlined className="contactIcon" />
              <span>Lahore, Pakistan</span>
            </div>
            {/* <div className="contactInnerDiv">
              <PhoneOutlined className="contactIcon" />
              <span>+92-300-1234567</span>
            </div> */}
            <div className="contactInnerDiv">
              <MailOutlined className="contactIcon" />
              <span>contactus@dmechs.com</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    // </footer>
  );
};

export default Footer;
