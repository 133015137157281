import React, { useEffect, useState } from "react";
import { Typography, Image, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { FadeInSection, FadeRightSection } from "../FadeInSection/index";
import "./Careers.scss";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "react-router-dom";
import createClient from "../../clientCareer";
import clientOfficialCareers from "../../clientOfficialCareers";
import { useParams } from "react-router-dom";
const { Title } = Typography;

const JobList = () => {

  const [jobData, setJobData] = useState([]);
  const [isLoading , setIsLoading] = useState(true)
 

  const { slug } = useParams();


  console.log(slug, "slug")

  useEffect(() => {
    clientOfficialCareers
    .fetch(`*[slug.current == "${slug}"]`)
    .then((data) => {
      console.log(data, "DATATA"); 
      // Log the fetched data to check what's being returned
      setJobData(data[0]);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    })
    .finally(() => setIsLoading(false));
  }, [slug]);

  console.log(jobData, "jobData");
  return (
    <>
    {isLoading ? (
        <h1 className="loaderCss">
          <Box sx={{ display: "flex" }}>
            <CircularProgress style={{ width: "100px", height: "100px" }} />
          </Box>
        </h1>
      ) : (
    <div className="container">
    <FadeInSection>
      <div className="col-10 container singleBlog">
        <Button icon={<ArrowLeftOutlined />}>
          <Link to={"/careers"}>Back</Link>
        </Button>
        <div className="titleDiv">
          <Title level={2}>{jobData?.title}</Title>
        </div>
        {/* {jobData.mainImage && jobData.mainImage.asset && (
          <Image
            src={jobData.mainImage.asset.url}
            alt="Blog Thumbnail"
            className="blogImage"
          />
        )} */}

        <FadeRightSection>
          <BlockContent
            blocks={jobData?.body}
          />
        </FadeRightSection>
      </div>
    </FadeInSection>
    </div>
     )}
    </>
  );
};

export default JobList;