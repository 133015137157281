import React, { useEffect } from "react";
import { Link as DOMLink, useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { Link } from "react-scroll";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../../assets/images/Logo/logo.webp";
import "./header.scss";
import { NavBar } from "../../pages/navbar";
import { useState } from "react";

const TopHeader = () => {

  const handleServiceClick = () => {
    const currentUrl = window.location.pathname;
    const homeUrl = "/";

    if(currentUrl !== homeUrl) {
      localStorage.setItem("scrollToServices", "true");
      window.location.replace(homeUrl);
    }
  };

  const handleScheduleClick = () => {
    const currentUrl = window.location.pathname;
    const homeUrl = "/";

    if (currentUrl !== homeUrl) {
      localStorage.setItem("scrollToSchedule", "true");
      window.location.replace(homeUrl);
    } 
  };

  useEffect(() => {
    if(window.location.pathname === "/"){
      const scrollToServices = localStorage.getItem("scrollToServices");
      if (scrollToServices === "true") {
        localStorage.removeItem("scrollToServices");
    
        setTimeout(function () {
          const servicesSection = document.getElementById("services");
          servicesSection.scrollIntoView({ behavior: "smooth" });
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if(window.location.pathname === "/"){
      const scrollToSchedule = localStorage.getItem("scrollToSchedule");
      if (scrollToSchedule === "true") {
        localStorage.removeItem("scrollToSchedule");
    
        setTimeout(function () {
          const scheduleSection = document.getElementById("call");
          scheduleSection.scrollIntoView({ behavior: "smooth" });
        }, 1000);
      }
    }
  }, []);

  return (
    <Row id="header" className="header backgroundColor applyZ">
      <Col span={24}>
        <div className="align-content-center justify-content-between ">
          <Row>
            <Col
              xs={{ offset: 1, span: 22 }}
              sm={{ offset: 1, span: 22 }}
              md={{ offset: 1, span: 22 }}
              lg={{ offset: 1, span: 22 }}
            >
              <nav className="navbar navbar-expand-xl navbar-expand-lg navbar-expand-md backgroundColor p-0 navWrapper">
                <DOMLink className="navbar-brand" to={"/"}>
                  <Row span={12}>
                    <Col>
                      <img
                        className="logoSize"
                        src={logo}
                        alt="logo"
                        title="DMECHS"
                        width={"3000"}
                        height={"3000"}
                        loading="lazy"
                      />
                    </Col>
                  </Row>
                </DOMLink>

                <div
                  className="collapse navbar-collapse menuList"
                  id="navbarNav"
                >
                  <ul className="navbar-nav align-items-center justify-content-between">
                    <li className="nav-item mr-2 ">
                      <DOMLink className="nav-link font-nav" to="/">
                        Home
                      </DOMLink>
                    </li>
                    <li className="nav-item mr-2">
                      <Link
                        className="nav-link font-nav"
                        to="services"
                        onClick={handleServiceClick}
                        offset={-50}
                        spy={true}
                        smooth={true}
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item mr-2">
                      <DOMLink className="nav-link font-nav" to="/blogs">
                        Blogs
                      </DOMLink>
                    </li>
                    <li className="nav-item mr-2 ">
                      <DOMLink className="nav-link font-nav" to="/careers">
                        Careers
                      </DOMLink>
                    </li>
                    <li className="nav-item mr-2 ">
                      <DOMLink className="nav-link font-nav" to="/contact">
                        Contact Us
                      </DOMLink>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link font-navc  third px-3"
                        onClick={handleScheduleClick}
                        to="call"
                        spy={true}
                        smooth={true}
                      >
                        Schedule Call
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="navbarDiv">
                  <NavBar />
                </div>
              </nav>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default TopHeader;
