import React from "react";
import { Row, Col } from "antd";


const TechCard = (props) => {
  return (
    <>
      <Col span={24} className={"align-items-center justify-content-center "}>
        <Row className={"align-items-center justify-content-center my-4"}>
          {props.image}
        </Row>
      </Col>
    </>
  );
};

export default TechCard;