import React, { useState, useEffect } from "react";
import { Typography, Image, Button, Row, Col, Card } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./blogs.scss";
import { Link, useParams } from "react-router-dom";
import { FadeInSection, FadeRightSection } from "../FadeInSection/index";
import sanityClient from "../../client.js";
import BlockContent from "@sanity/block-content-to-react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const { Title, Text } = Typography;

const BlogPage = () => {
  const [singleBlog, setSingleBlog] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();
  const [suggestedBlogs, setSuggestedBlogs] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"] {
        title,
        "author": author->name,
        body,
        mainImage{
          asset->{
          _id,
          url
          },
          alt
        }
      }`
      )
      .then((data) => setSingleBlog(data[0]))
      .catch(console.error)
      .finally(() => setIsLoading(false));

    sanityClient
      .fetch(
        `*[_type == "post" && slug.current != "${slug}"]{
        title,
        slug,
        mainImage{
          asset->{
          _id,
          url
          },
          alt
        }
    }[0...3]`
      )
      .then((data) => setSuggestedBlogs(data))
      .catch(console.error);
  }, [slug]);

  const handleSuggestedBlogClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {isLoading ? (
        <h1 className="loaderCss">
          <Box sx={{ display: "flex" }}>
            <CircularProgress style={{ width: "100px", height: "100px" }} />
          </Box>
        </h1>
      ) : (
        <div className="container mt-5">
          <FadeInSection>
            <div className="col-10 container singleBlog">
              <Button icon={<ArrowLeftOutlined />}>
                <Link to={"/blogs"}>Back</Link>
              </Button>
              <div className="titleDiv">
                <Title className="innerTitle" level={2}>
                  {singleBlog.title}
                </Title>
                <Text ellipsis={{ rows: 3 }}>By: {singleBlog.author}</Text>
              </div>

              {singleBlog.mainImage && singleBlog.mainImage.asset && (
                <Image
                  src={singleBlog.mainImage.asset.url}
                  alt="Blog Thumbnail"
                  className="blogImage"
                />
              )}
              <FadeRightSection>
                <div className="descriptionDiv">
                  <BlockContent blocks={singleBlog.body} />
                </div>
              </FadeRightSection>
            </div>
          </FadeInSection>

          <div className="container suggestedBlogs">
            <h2>Suggested Blogs</h2>
            <Row gutter={[16, 16]}>
              {suggestedBlogs.map((blog) => (
                <Col key={blog.slug.current} xs={24} sm={12} md={8} lg={8} xl={8}>
                  <Link to={`/blogs/${blog.slug.current}`} onClick={handleSuggestedBlogClick}>
                    <Card
                      hoverable
                      className="cardCss"
                      style={{ cursor: "pointer" }}
                      cover={<img alt={blog.title} src={blog.mainImage.asset.url} />}
                    >
                      <div style={{ height: "120px" }}>
                        <Title level={4} ellipsis={{ rows: 2 }}>
                          {blog.title}
                        </Title>
                        <div style={{ textAlign: "left", marginTop: "20px" }}>
                          <Button className="third1">Read Full Article</Button>
                        </div>
                      </div>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogPage;
