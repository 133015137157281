import React from "react";
import "../../assets/scss/pages.scss";
import ".";
import { Row, Col } from "antd";
import ServiceCard from "./Service";
import {
  CloudServerOutlined,
  DeploymentUnitOutlined,
  FundProjectionScreenOutlined,
  GlobalOutlined,
  LaptopOutlined,
  MobileOutlined,
  PartitionOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";

let services = [
  {
    icon: <GlobalOutlined className={"font-38 icon-color"} />,
    title: "Web Development",
    description: "Custom websites, portals, marketplaces and platforms",
  },
  {
    icon: <MobileOutlined className={"font-38 icon-color"} />,
    title: "Mobile Development",
    description:
      "Applications designed to run on mobile devices, such as smartphones & tablets",
  },
  {
    icon: <CloudServerOutlined className={"font-38 icon-color"} />,
    title: "Cloud DevOps",
    description: "Cloud deployments, migrations and cost optimizations",
  },
  {
    icon: <MobileOutlined className={"font-38 icon-color"} />,
    title: "Data Analytics",
    description: "Involves using data mining and analysis techniques",
  },
  {
    icon: <DeploymentUnitOutlined className={"font-38 icon-color"} />,
    title: "Managed IT Services",
    description:
      "Outsourcing the management of IT systems and infrastructure to an external provider",
  },
  {
    icon: <FundProjectionScreenOutlined className={"font-38 icon-color"} />,
    title: "Software Development",
    description: "Custom software application to meet specific bussiness needs",
  },
  {
    icon: <LaptopOutlined className={"font-38 icon-color"} />,
    title: "IT Consulting",
    description:
      "Infrastructure design, guidance and advice on technology strategy",
  },
  {
    icon: <PartitionOutlined className={"font-38 icon-color"} />,
    title: "Network Setup and Maintenance",
    description: "Designing, installing, and managing computer networks",
  },
  {
    icon: <SecurityScanOutlined className={"font-38 icon-color"} />,
    title: "Cybersecurity",
    description:
      "Protecting computer systems and networks from unauthorized access or attacks",
  },
];
const Service = (props) => {
  return (
    <>
      <Col
        md={{ span: 24 }}
        lg={{ span: 24 }}
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        className=" mt-5 d-flex align-items-center justify-content-between"
      >
        <Row
          className="justify-content-center"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          {services.map((s, i) => (
            <ServiceCard key={"service" + i} service={s} />
          ))}
        </Row>
      </Col>
    </>
  );
};

export default Service;
