import React from 'react';
import pageNotFound from '../assets/images/404.png'

const PageNotFound = () => {
  return (
      <div className="container  p-5">
        <div className="container mt-5">
          <div className="text-center mt-5">
            <h2 className="mt-5 text-secondary">O O P S <span className="ml-1">!</span></h2>

           <img height={190} src={pageNotFound}/>
            <h2 className='mt-5'>Page Not Found</h2>
            <p className="font-weight-lighter text-black-50">The page you are looking for could not be found.</p>
          </div>
        </div>
      </div>
  );
};

export default PageNotFound;
