import React from "react";
import ".";
import { Row, Col } from "antd";
import "./AboutUs.scss";
import { FadeInSection } from "../FadeInSection";
import AboutVideo from "../../assets/images/aboutus/bussiness-about-vid.webm";

export const AboutUs = (props) => {
  return (
    <>
      <Col
        md={{ span: 24 }}
        lg={{ span: 24 }}
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        className=" aboutBox aboutUs-background"
      >
        <FadeInSection>
          <Row className={"alignItems"}>
            <Col className={"imgD"} md={{ span: 24 }} lg={{ span: 3 }}>
              <video autoPlay muted loop>
                <source src={AboutVideo} type="video/webm" />
              </video>
            </Col>
            <Col
              className="content-about"
              md={{ span: 24 }}
              xl={{ span: 16 }}
              lg={{ span: 14 }}
            >
              <Row className={"p-3 "}>
                <Col
                  className="textCss"
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  sm={{ span: 24 }}
                >
                  <h1 style={{ color: "white" }}>About Us</h1>
                  <p className={"text"}>
                    Dmechs builds custom software for startups and small
                    businesses. We specialize in custom web design and
                    development, app development, voice apps, AWS cloud and big
                    data analytics consulting
                  </p>
                </Col>

                <Col
                  className="textCss"
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  sm={{ span: 24 }}
                >
                  <h2 style={{ color: "white", marginTop: "25px" }}>
                    Our Philosophy
                  </h2>
                  <p className={"text"}>
                    As a technology solutions provider, it is our responsibility
                    to relieve the client of all technology and its operational
                    issues. Clients should be able to fully focus on growing
                    their business and customers. Our sole objective is client
                    success; all solutions we propose and implement should be
                    based on putting our customers best interest first
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </FadeInSection>
      </Col>
    </>
  );
};
