import React from "react";
import { Row, Col } from "antd";
import "./Technologies.scss";
import { Carousel } from "antd";
import TechCard from "./technology";

import ReactSVG from "../../assets/images/Technologies/react-2.svg";
import NodeSVG from "../../assets/images/Technologies/nodejs-icon.svg";
import CssSVG from "../../assets/images/Technologies/css-3.svg";
import DockerSVG from "../../assets/images/Technologies/docker-3.svg";
import PostgresrSVG from "../../assets/images/Technologies/postgresql.svg";
import AWSsvg from "../../assets/images/Technologies/aws-2.svg";
import SQLsvg from "../../assets/images/Technologies/mysql-6.svg";
import MongoSVG from "../../assets/images/Technologies/mongodb-icon-1.svg";
import AngularSVG from "../../assets/images/Technologies/angular-icon-1.svg";
import NestSVG from "../../assets/images/Technologies/nestjs.svg";
import ExpressSVG from "../../assets/images/Technologies/express-109.svg";
import VueSVG from "../../assets/images/Technologies/vue-9.svg";
import ReactNative from "../../assets/images/Technologies/react-native-1.svg";
import JavaScriptSVG from "../../assets/images/Technologies/javascript-1.svg";
import HTML5SVG from "../../assets/images/Technologies/html-1.svg";
import BootstrapSVG from "../../assets/images/Technologies/bootstrap-5-1.svg";
import AntSVG from "../../assets/images/Technologies/antd.svg";
import ReduxSVG from "../../assets/images/Technologies/redux.svg";
import GraphQLSVG from "../../assets/images/Technologies/graphql-logo-2.svg";
import { FadeInSection } from "../FadeInSection";

let technologies = [
  {
    image: <img id="tech" className={"imageSize"} alt="react logo" src={ReactSVG} />,
    title: "react.js",
  },
  {
    image: <img id="tech" className={"imageSize"} alt="nodejs logo" src={NodeSVG} />,
    title: "NodeJs",
  },
  {
    image: <img id="tech" className={"imageSize"} alt="mongodb logo" src={MongoSVG} />,
    title: "MongoDB",
  },
  {
    image: <img id="tech" className={"imageSize"} alt="docker logo" src={DockerSVG} />,
    title: "Docker",
  },
  {
    image: <img id="tech" className={"imageSize"} alt="Css logo" src={CssSVG} />,
    title: "Css Icon",
  },
  {
    image: <img id="tech" className={"imageSize"} alt="aws logo" src={AWSsvg} />,
    title: "AWS services",
  },

  {
    image: <img className={"imageSize"} alt="angular logo" src={AngularSVG} />,
    title: "Angular",
  },
  {
    image: <img className={"imageSize"} alt="express logo" src={ExpressSVG} />,
    title: "ExpressJs",
  },
  {
    image: <img className={"imageSize"} alt="nest logo" src={NestSVG} />,
    title: "NestJs",
  },
  {
    image: <img className={"imageSize"} alt="docker logo" src={PostgresrSVG} />,
    title: "PostgresQL",
  },
  {
    image: <img className={"imageSize"} alt="vue logo" src={VueSVG} />,
    title: "VueJs",
  },
  {
    image: <img className={"imageSize"} alt="react logo" src={ReactNative} />,
    title: "React Native",
  },
  {
    image: (
      <img className={"imageSize"} alt="javascript logo" src={JavaScriptSVG} />
    ),
    title: "JavaScript",
  },
  {
    image: <img className={"imageSize"} alt="html5 logo" src={HTML5SVG} />,
    title: "HTML 5",
  },
  {
    image: (
      <img className={"imageSize"} alt="bootstrap logo" src={BootstrapSVG} />
    ),
    title: "Bootstrap",
  },
  {
    image: <img className={"imageSize"} alt="antdesign logo" src={AntSVG} />,
    title: "Ant Design",
  },

  {
    image: <img className={"imageSize"} alt="redux logo" src={ReduxSVG} />,
    title: "Redux",
  },
  {
    image: <img className={"imageSize"} src={GraphQLSVG} />,
    title: "GraphQL",
  },
  {
    image: <img className={"imageSize"} alt="mysql logo" src={SQLsvg} />,
    title: "MySQL",
  },
];
//Using Carousel Card Antd
const Technologies = (props) => {
  return (
    <>
      <FadeInSection>
        <Row>
          <Col span={24} className="align-items-center justify-content-around">
            <div className="mt-5 text-center fontCss">
              <h1>Our Core Technologies</h1>
            </div>
            <Row className="align-items-center d-flex justify-content-around my-5">
              <Col
               span={24}
                >
                <Carousel
                  autoplay
                  autoplaySpeed={2000}
                  dots={false}
                  slidesToShow={8}
                  infinite
                  responsive={[
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 8,
                      },
                    },
                    {
                      breakpoint: 992,
                      settings: {
                        slidesToShow: 6,
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 4,
                      },
                    },
                    {
                      breakpoint: 667,
                      settings: {
                        slidesToShow: 3,
                      },
                    },
                  ]}
                >
                  {technologies.map((tech, index) => (
                    <TechCard
                      key={"technology" + index}
                      image={tech.image}
                      title={tech.title}
                    />
                  ))}
                </Carousel>
              </Col>
            </Row>
          </Col>
        </Row>
      </FadeInSection>
    </>
  );
};

export default Technologies