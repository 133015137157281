import React from "react";
import "../../assets/scss/pages.scss";
import Service  from "../services";
import "./landing.scss";
import { Row, Col } from "antd";
import { scrollUp } from "../../utils/utilSvc";
import { AboutUs } from "../AboutUs";
import Technologies  from "../Technologies";
import PeopleAndTechnology  from "../PeopleAndTechnology";
import EngagementModal from "../EnagegmentModal";
import "@lottiefiles/lottie-player";
import { FadeInSection } from "../FadeInSection";
import { TypeAnimation } from "react-type-animation";
import HeroVideo from '../../assets/images/Hero/transcode.webm'

const Landing = () => {
  scrollUp();

  return (
    <Row>
      <Col span={24}>
        <Row id="home" className={"loadImage"}>
          <div>
            <video autoPlay loop muted>
              <source
                src={HeroVideo}
                type="video/webm"
              />
            </video>
            <Col
              span={24}
              className="text-center"
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                position: "absolute",
                left: 0,
                top: "3.2%",
                zIndex: 1,
              }}
            >
              <Row justify="start">
                <Col
                  xs={{ span: 20, offset: 1 }}
                  sm={{ span: 20, offset: 1 }}
                  md={{ span: 12, offset: 2 }}
                  lg={{ span: 20 }}
                >
                  <div
                    className=" text-left text-align titleWrapper"
                  >
                    <h1 className="text-uppercase top-heading">
                      Empower the world with
                    </h1>
                    <h1 className="text-uppercase top-heading">DMechs</h1>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1 className="text-uppercase top-heading">
                      We Build Solutions For
                    </h1>
                    <br></br>
                    <TypeAnimation
                      preRenderFirstString={true}
                      sequence={[
                        1000,
                        "Software Development",
                        1000,
                        "Web Development",
                        1000,
                        "Cloud DevOps",
                        1000,
                        "Data Analytics",
                        1000,
                        "Cybersecurity",
                        1000,
                        "Hybrid Development",
                        1000,
                      ]}
                      speed={30}
                      repeat={Infinity}
                      className="top-heading2 text-uppercase"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
          <div
            style={{
              position: "absolute",
              right: "3.5%",
              top: "5.2%",
              zIndex: 1,
            }}
          >
            <div class="container">
              <div class="avatar" style={{ border: " 5px #2754c4 solid" }}>
                <a>
                  <lottie-player
                    autoplay
                    loop
                    mode="normal"
                    src="https://assets3.lottiefiles.com/packages/lf20_kDfr8a.json"
                  ></lottie-player>
                </a>
              </div>
            </div>
            <div class="container">
              <div class="avatar">
                <a >
                  <lottie-player
                    autoplay
                    loop
                    mode="normal"
                    src="https://assets4.lottiefiles.com/packages/lf20_zvcyhdqv.json"
                  ></lottie-player>
                </a>
              </div>
            </div>
          </div>
        </Row>

        <Row id="services" className={"bg-color"}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 22, offset: 1 }}
          >
            <Row className="pt-5 mb-2" style={{ justifyContent: "center" }}>
              <FadeInSection key={"landing-second-half"}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                  <span className="text-center main-heading-42 text-color fontCss">
                    Services
                  </span>
                </Col>
              </FadeInSection>
            </Row>

            <Row
              className=" my-2"
            >
              <Col span={24}>
                <Service />
              </Col>
            </Row>

            <Row id="about" className="marginTop">
              <Col span={24} className={"pb-5"}>
                <AboutUs />
              </Col>
            </Row>

            <Row id="engagement-modal">
              <Col span={24}>
                <FadeInSection>
                  <EngagementModal />
                </FadeInSection>
              </Col>
            </Row>

            <Row id="technology">
              <Col span={24}>
                <FadeInSection>
                  <Technologies />
                </FadeInSection>
              </Col>
            </Row>

            <Row id="call">
              <Col span={24}>
                <FadeInSection>
                  <PeopleAndTechnology />
                </FadeInSection>
              </Col>
            </Row>

          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Landing